import React from 'react'
import {Link} from 'gatsby'
import ProductIcon from "./icon/productIcon";

export default ({icon, title, text, slug}) => {


    const splitTitle = (name) => {
        if (name.includes(':')) {
            let fragments = name.split(':')
            return {
                product: fragments[0],
                name: fragments[1]
            }
        }
        return {
            product: null,
            name
        }
    }
    return (
        <div className='flex flex-col items-center pb-4 md:py-4 mb-12 md:mb-0'>
            <Link to={`/product/${slug}`}
                  className='group flex flex-col items-center rounded-full h-48 w-48 bg-brand-grey-darker text-gray-200 hover:bg-brand-red-light hover:text-white transition-all duration-300 ease transform hover:scale-105'>

                <span className='mt-8 mb-2 h-6 w-6 relative mb-4'>
                                                <span
                                                    className='text-2xl group-hover:opacity-0 absolute inset-0 flex items-center justify-center'>
                                                    <ProductIcon icon={icon}
                                                                 color='text-brand-grey-lighter'/>
                                                </span>
                                                <span
                                                    className='text-2xl opacity-0 group-hover:opacity-100 inset-0 flex items-center justify-center '>
                                                    <span
                                                        className={`fa fa-arrow-right`}/>
                                                </span>
                                            </span>
                <span className='text-center px-4 text-lg leading-tight'>
                                                {title}
                                            </span>


            </Link>
            <div className='body-text text-center py-8 hidden md:block'>
                {text}
            </div>
        </div>
    )
}
