import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import SEO from '../components/seo'
import Product from '../components/product'
import Hero from '../components/hero'

export const query = graphql`
query ProductsPageQuery {
    products: allSanityProduct(
        filter: {slug: {current: {ne: null}}}
        sort: {fields: [order], order: DESC}
    ) {
        edges {
            node {
                id
                title
                excerpt
                icon
                productIcon
                slug {
                    current
                }
            }
        }
    }
}
`

class ProductPage extends React.Component {
    constructor(props) {
        super(props);
        const {data} = props
        this.state = {
            products: (data || {}).products,
        }
    }

    render() {
        return (
            <>
                <SEO
                    title='Produkter'
                />

                <Hero
                    title='Våre produkter og tjenester'
                />

                <Container>
                    <div className='pb-0 -mt-24 relative z-20'>
                        <div className='flex flex-col md:flex-row -mx-10 flex-wrap md:pb-20'>
                            {this.state.products && this.state.products.edges.map(({node}, index) => (
                                <div className='px-10 w-full md:w-1/3'>
                                    <Product
                                        key={`product-${index}`}
                                        icon={node.productIcon || node.icon}
                                        title={node.title}
                                        text={node.excerpt}
                                        slug={node.slug.current}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}

export default ProductPage
